import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%"
    },
    disclosureStyle: {
        fontWeight: "400",
        fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
        fontSize: "15px",
        width: "100%",
        display: "block",
        color: "#282B3E",
    },
    gridSeparation: {
        paddingTop: "80px",
    },

}));

const Disclosures = () => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={classes.gridSeparation}>
                <span className={classes.disclosureStyle} >
                    <p>There is no charge from John Hancock, but message and data rates may apply.</p>
                    <p>Delivery of the security code may be delayed due to: service outages affecting your telephone device,
                    wireless or internet provider; technology failures; and system capacity limitations; and other
                    connection issues you or your device may be experiencing.</p>
                    <p>If you have any questions or need assistance, please contact customer support.</p>
                    <p>You may change your preferences and update your contact information by logging into our website /
                        or choosing a different method to receive another security code.</p>
                    <p>Note: Please be aware that anyone with access to your email account or telephone device may
                        be able to log in into your account, view your account information, and change your preferences.</p>
                    <p>We value your privacy. For details about our Privacy Policy; <a href="https://www.johnhancock.com/privacy-security.html" target="_blank">click here.</a></p>
                </span>

            </Grid>
        </Grid>
    );
};

export default Disclosures;
