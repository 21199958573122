const successBodyText = 'Your account registration is complete. Click the button below to login.';
const successCTAText = 'Go to my account';

const CUSTOMIZATIONS = [
  {
    appId: '2320147',
    appName: 'DTC',
    successBody: successBodyText,
    successCTA: successCTAText
  },
  {
    appId: '473048',
    appName: 'MyDashboard',
    successBody: successBodyText,
    successCTA: successCTAText
  },
  {
    appId: '362772',
    appName: 'LIFE',
    successBody: successBodyText,
    successCTA: successCTAText
  },
  {
    appId: '266976',
    appName: 'LTC',
    successBody: successBodyText,
    successCTA: successCTAText
  }
];

export default CUSTOMIZATIONS;