import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  headerText: {
    margin: "0 auto",
    fontWeight: "600",
    fontFamily: "\"Manulife JH Sans\"",
    fontSize: "48px",
    color: "#282B3E",
    lineHeight: "58px"
  },
  subText: {
    fontWeight: "300",
    fontFamily: "\"Manulife JH Sans\"",
    fontSize: "22px",
    color: "#282B3E",
    lineHeight: "34px"
  },
  gridSeparation: {
    paddingBottom: "40px"
  },
  errorWrapper: {
    paddingBottom: "40px"
  },
  errorIcon: {
    float: "left",
    height: "17px",
    width: "17px",
    marginTop: "2px",
    color: "black !important",
    content: " ",
    background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',

  },
  errorLabelStyle: {
    color: "#C14A36 !important",
    fontWeight: "300px",
    marginLeft: "5px"
  },
}));

const PageHeader = ({ text, subText = null, errorType = null, maintenanceDetails = null, tokenInfo, step }) => {
  const classes = useStyles();
  return (
    <Grid container>
      {
        errorType === 'Maintenance' &&
        <Grid item md={9} xs={12}>
          <div className={classes.errorWrapper}>
            <div className={classes.errorIcon} />
            <span className={classes.errorLabelStyle}>
              {
                maintenanceDetails?.identity ? maintenanceDetails.identity : "The system is currently undergoing scheduled maintenance. If you are experiencing registration issues, please try again later. Thank you for your patience."
              }
            </span>
          </div>
        </Grid>
      }
      <Grid item xs={12} className={classes.gridSeparation}>
        <h1 className={classes.headerText}>{text}</h1>
      </Grid>
      {subText && step===0 && 
        <Grid item xs={12} className={classes.gridSeparation}>
          <span className={classes.subText}>{subText}</span>

          <p className={classes.subText}>
            Welcome to the John Hancock CareGiver application. This is your one-stop for managing and submitting care session charges for {tokenInfo.insuredfn} {tokenInfo.insuredln}'s long-term care policy. <br /> <br />
            Get started by creating your CareGiver profile and safeguard your account from unauthorized access.
          </p>

        </Grid>
      }
      {subText && (step===1 || step===3) && 
        <Grid item xs={12} className={classes.gridSeparation}>
          <span className={classes.subText}>{subText}</span>
          <p className={classes.subText}>
          </p>
          
        </Grid>
      }
      {subText && (step===2) && 
        <Grid item xs={12} className={classes.gridSeparation}>
          <span className={classes.subText}>{subText}</span>
          <p className={classes.subText}>
          Thanks for taking this final step. We're committed to keeping your personal information confidential to keep your profile safe.
          </p>
          
        </Grid>
      }

    </Grid>
  );
};

export default PageHeader;
