import axios from 'axios';

function validatePasswordAjax(payload, onPasswordValidatedResponse) {
  
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
  };
  axios.post(
    process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/validatepassword` : "https://provider.registration-uat.johnhancock.com/backend/validatepassword",//"http://localhost:55047/validatepassword",
    payload,
    { headers }).then(response => {
        onPasswordValidatedResponse(response, null);
    }).catch(error => {
        console.log("validatePasswordAjax:error==", error);
        onPasswordValidatedResponse(null, error);
    });
}

export default validatePasswordAjax;
