import { Button } from '@manulife/mux';
import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { MyContext } from '../Context/MyContext';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    buttonStyle:{
        width:"150px"
    }
}))

const StartOverComponent = (props) => {

    
    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(false);
    }, [])

    function backToRegistration(){
        window.location.reload();
    }    

    const classes = useStyles();

    return (
        <div data-testid="session-root" className="session-root">
            <Grid item xs={12} className={classes.gridSeparation}></Grid>
            <Grid item md={12} xs={12}>
                  
                <Button
                    name="StartOver"
                    data-testid="session-submitButton"
                    id="StartOver"
                    onClick={e => backToRegistration()}
                    customStyle={{
                        buttonStyle: {
                            width: '10%',
                            height: '5%',
                        },
                        rootStyle: {
                            width: "70%",                            
                        },
                    }}
                    disabled={false}
                    >Start over
                </Button>

                </Grid>
        </div>
    );
}

export default StartOverComponent;
