import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Container } from '@material-ui/core'
import { ReactComponent as FooterLogo } from './images/footer-logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0, // for the sticky footer
        width: "100%",
        background: "#2F3242",
        marginTop: "100px",
    },
    container: {
        paddingTop: "60px",
        paddingBottom: "60px",
        flexFlow: "column-reverse",
        [theme.breakpoints.up("sm")]: {
            flexFlow: "row",
        }
    },
    logoStyles: {
        textAlign: "center",
        "& svg": {
            maxWidth: "100%"
        },
        [theme.breakpoints.up("sm")]: {
            paddingRight: "50px",
            textAlign: "left"
        }
    },
    bodyStyles: {
        padding: "0 30px 30px",
        [theme.breakpoints.up("sm")]: {
            padding: 0
        }
    },
    labelStyles: {
        margin: 0,
        fontWeight: "normal",
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "15px",
        display: "block",
        color: "#FFFFFF",
        lineHeight: "24px",
        opacity: "0.8"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    linkStyles: {
        color: "#FFFFFF",
        textDecoration: "none",
        fontSize: "14px",
        whiteSpace: "nowrap",
        '&:hover': {
            textDecoration: "underline"
        }
    },
    linkList: {
        display: "flex",
        listStyle: "none",
        padding: 0,
        margin: 0,
        opacity: "0.8",
        "& li": {
            '&:after': {
                content: '"|"',
                display: 'inline-block',
                color: "#FFFFFF",
                padding: '0 1ch',
            },
            '&:last-child:after': {
                content: "none"
            }
        }
    },
    newLine: {
        display: "block"
    }
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <Grid className={classes.root}>
            <Container>
                <Grid container spacing={0} className={classes.container}>
                    <Grid item md={3} xs={12} className={classes.logoStyles}>
                        <FooterLogo />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container className={classes.bodyStyles}>
                            <Grid item xs={12} className={classes.gridSeparation}>
                                <ul className={classes.linkList}>
                                    <li>
                                        <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/accessibility.html" target="_blank">Accessibility</a>
                                    </li>
                                    <li>
                                        <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/privacysecurity.html" target="_blank">Privacy &amp; Security</a>
                                    </li>
                                    <li>
                                        <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/legal.html" target="_blank">Legal</a>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={classes.labelStyles}>
                                    If you need assistance registering or already have an online account with John Hancock, <br/>please call <a href="tel:1-844-798-3001" className={classes.linkStyles}>844-798-3001</a>, Monday - Friday 8:00AM - 5:00PM EST.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} /> {/* empty right gutter to balance layout */}
                </Grid>
            </Container>
        </Grid>
    );
}

export default Footer;
