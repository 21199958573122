


export function hasNumbers(t) {
    let regex = /\d/g;
    return regex.test(t) ? null : "Passwords require at least one number."
}

export function hasUpper(t) {
    
    return t.toLowerCase() !== t ? null : "Passwords require at least one uppercase letter."
}

export function hasLower(t) {
    
    return t.toUpperCase() !== t ? null : "Passwords require at least one lowercase letter."
}

export function hasSpecial(t) {
    // eslint-disable-next-line
    return /[!@#$%^&~`*()_+\-=\[\]{};':"\\|,.<>/?]/g.test(t) ? null : "Passwords require at least one special character."
}

export function noRepeat(str) {
    // eslint-disable-next-line
    return str !== "" && !(/([a-zA-Z0-9!@#$%^&~`*()_+\-=\[\]{};':"\\|,.<>/?])\1\1\1/).test(str) ? null : "Passwords"
}

export function hasEight(str) {
    return str.length >= 8 ? null : "Passwords require at least eight characters." 
}

export function isValidPasword(password) {
    return !hasNumbers(password) && !hasUpper(password) && !hasLower(password) && !hasSpecial(password) && !hasEight(password)
}



const ValidatePassword = {
    hasNumbers, hasUpper, hasLower, hasSpecial, hasEight, isValidPasword
}

export default ValidatePassword;