
import moment from "moment";
import Strings from "../../constants/strings.json"
const zxcvbn = require('zxcvbn');
export function validate(formValues, name, errorState) {

    if (name === 'userName') {
     
        if (!formValues.userName) {
            errorState.userName = { id: 'userName', msg: 'Enter User ID', isError: true };
        } 
        else {
            errorState.userName = { id: 'userName', msg: '', isError: false };
        }
        
    }

    if (name === 'pwd')  {
        if (!formValues.pwd) {
            errorState.pwd = { id: 'pwd', msg: 'Enter password', isError: true };
        } 
        else {
            errorState.pwd = { id: 'pwd', msg: '', isError: false };
        }
    }

    return errorState
}

export function validateUserName(name, value) {
    const re = /[A-Za-z]+/g;  //if there is one or more a-z or A-Z name - valid
    const testString = String(value).split(' ').join(''); //remove spaces for regex test - they are valid
    // return re.test(testString) && (value.length >= 6 && value.indexOf('@') === -1);
    return re.test(testString) && (value.length >= 6);
}

export function getUserNameValidationError(value) {
    const all_special_and_number = /(\W+)|(\d+)|(\W+d*)|(\d+\W*)/g; //check for mix of only number and special characters - not valid
    let message = '';

    if (String(value).length <= 6) {
        message = "User ID must be at least 6 characters.";
    }
    else if (all_special_and_number.test(String(value).split(" ").join("")))  //don't consider spaces - they are valid
    {
        message = "User ID cannot contain all numbers and/or special characters";
    }
    else {
        message = "Enter user ID";
    }
    return message;
}

export function validatePassword(password) {

    let result = zxcvbn(password);
    let length = password.length;
    let splChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let checkLength = length >= 9;
    let hasTriple = (/([a-zA-Z0-9!@#$%^&~`*()_+\-=\[\]{};':"\\|,.<>/?])\1\1/).test(password);
    let hasSpecial = splChars.test(password);
    let hasLower = /[a-z]/.test(password);
    let hasUpper = /[A-Z]/.test(password);
    let hasDigit = /[0-9]/.test(password);
    let hasAllPolicies = hasLower && hasSpecial && (!hasTriple) && hasUpper
        && hasDigit && checkLength;

    if (result.score >= 2 && hasAllPolicies) {
        return true;
    } else {
        return false;
    }
}

export default {
    validate,   validateUserName,     getUserNameValidationError, validatePassword
}