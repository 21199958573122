import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import {Button, RadioGroup, INPUT_VARIANT, Progress} from '@manulife/mux';
import { getErrorMessage, isEmpty } from '../../utils/Validations';
import { useState } from 'react';
import { MyContext } from '../../Context/MyContext';
import Service from '../../Services/Service';
import Constants from '../../utils/Constants';
import Codes from '../../constants/Codes.json';
import InputError from './../InputError';
import EncounteredError from '../EncounteredError/EncounteredError';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontFamily: Constants.FONT_FAMILY,
        fontWeight: "600",
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "24px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    nameStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "26px"
    },
    sublabel: {
        fontWeight: "400",
        fontFamily: Constants.FONT_MFA_TEXT,
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "24px"
    },
}))



const MfaTypeComponent = (props) => {
    const context = useContext(MyContext);    
    const { appId, errorState, setErrorState,setFormValues,formValues, systemErrorCount, setSystemErrorCount, isAwaitingApiCall, setIsAwaitingApiCall, onClick} = props;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
   
    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })

    const changeHandler = (name, value) => {
        const newFormValues = {...formValues, [name]: value };
        setFormValues(newFormValues);       
    }

    const [formErrors, setFormErrors] = useState({
        mfaType: { id: 'mfaType', errMsg: '', isError: false }
    });


    function submit(){
        if(formValues.mfaType){
            sendMfaCode();
        } else{
            setFormErrors({ ...formErrors, mfaType: { id: 'mfaType', errMsg: getErrorMessage("mfaType"), isError: true } });
        }
    }

    function sendMfaCode(){
        setLoading(true);
        let phonenumber = formValues.phoneNumber.trim().replace(/[^a-zA-Z0-9 ]/g, '');
        phonenumber = phonenumber.replaceAll(' ','');
        const payload = {
            "guid": props.tokenInfo.ecryptGuid.trim(),
            "phonenumber": phonenumber,
            "type": formValues.mfaType,
            "appid" : Constants.APP_ID,
            "appId" : Constants.APP_ID           
        };

        Service.generateMfaCodeCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code < Constants.ERROR_CODE){
                props.onClick(3);               
            } else if(response.code === "9052"){
                props.onClick(7);    
            }else if(response.code ==="9000" || response.code==="9033"){
                //Exceeded MFA attempts redirect to error page.
               props.onClick(8); 
            }else if(response.code === "9022"){
                let message = Codes.message[response.code] || Codes.message.something_went_wrong;
                setErrorState({ ...errorState, ...{ mfaType: { id: 'mfaType', errMsg: message, isError: true } } });         
                setSystemErrorCount(0);
            }else if(response.code === "2002"){ // Message not enqueued
                setSystemErrorCount(systemErrorCount+1);
            }else{
                let message = Codes.message[response.code] || Codes.message.something_went_wrong;
                setErrorState({ ...errorState, ...{ mfaType: { id: 'mfaType', errMsg: message, isError: true } } });                
                setSystemErrorCount(0);
            }
        }).catch(error => {
            setLoading(false);
            console.log("error sendMfaCode ========>", error);
            if (error.message === "Network Error") {
            }
            props.onClick(7);     
          
        });
    }

    const createLabelText = () => {
        let mfaValue = []       
        const phoneNum = formValues.phoneNumber.replace(/[^\w]/gi, '');
        const textLabel = 'Text me at XXX-XXX-' + phoneNum.substring(6);
        const callLabel = 'Call me at XXX-XXX-' + phoneNum.substring(6);
    
        mfaValue.push({ value: 'SMS', label: textLabel })
        mfaValue.push({ value: 'VOICE', label: callLabel })
    
        return mfaValue
    }
    
    return (
        <div className={classes.root}>
            <Grid data-testid="mfaType-root" container md={12} xs={12} spacing={0}>  
                {
                    systemErrorCount > 0 &&
                        <EncounteredError systemErrorCount={systemErrorCount}/>
                }

                <Grid item md={12} xs={12}>
                <label className={classes.labelStyle}>How would you like to receive your confirmation code? <br/> Message and data rates apply.</label>  
                </Grid>
                <Grid item md={12} xs={12}>
                    <RadioGroup
                        name="mfaType"
                        onChange={e => changeHandler("mfaType", e)}
                        selected={formValues.mfaType}
                        direction="column"
                        className={classes.sublabel}
                        customStyle={
                            {
                                labelStyle: {
                                    fontFamily: "Manulife JH Sans",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "8px",
                                    lineHeight: "20px",
                                    color: "#282B3E",
                                    alignSelf: "center"
                                }
                            }
                        }
                        values={createLabelText()}
                    />                    
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    {(formErrors.mfaType.isError) && <InputError inputId="mfaType" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    <Button
                        name="submit"
                        data-testid="mfaType-submitButton"
                        customStyle={{
                            buttonStyle: {
                                display: "flex",
                                flexDirection: "column",
                                width: "150px",
                                height: "60px",
                                minWidth: "0px",
                                borderRadius: "0px",
                                padding: "0px"
                            }
                        }}
                        id="submitButton"
                        onClick={e => submit()}
                        disabled={isLoading}>Next</Button>
                    {isLoading && <div className="progress-container">
                                <Progress                             
                                    data-testid="mfaType-loader"
                                    isFloating={false} customStyle={{
                                    spinnerStyle: {
                                        position: "relative",
                                        height: "40px",
                                        width: "40px"
                                    }
                                }} />
                                <p className="loading-text">Please wait while we process your information</p>
                            </div>
                    }
                </Grid>                
            </Grid>
        </div>
    )
}
export default MfaTypeComponent;