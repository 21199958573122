
const MESSAGE_API = "/message";
const VALIDATE_LINK_API = "/linkvalidate";
const POST_CALL_API = "/postCall";
const VALIDATE_USERNAME_API = "/validateusername";
const VALIDATE_MFA_CODE_API = "/validateMFA";
const REGISTER_API = "/register";
const GENERATE_MFA_CODE_API = "/generateMFA"


export default {
    MESSAGE_API,
    VALIDATE_LINK_API,
    POST_CALL_API,
    VALIDATE_USERNAME_API,
    REGISTER_API,
    GENERATE_MFA_CODE_API,
    VALIDATE_MFA_CODE_API
};