import Utility from "./utility";

export const getQueryParams = () => {
  const search = new URLSearchParams(window.location.search);  
  
  
  let appId = Utility.sanitize(search.get('appid'));
  let jwt = Utility.sanitize(search.get('jwt'));

  return {
    appId: appId,
    jwt: jwt,
  };
};