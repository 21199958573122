import { CUSTOMIZATIONS } from '../constants';
import { getQueryParams } from './routeUtils';
import { equalsIgnoreCase } from './stringUtils';

export const getCustomization = (appName = null, appId = null) => {
  if (!appName && !appId) {
    const queryParams = getQueryParams();

    appName = queryParams.appName;
    appId = queryParams.appId;
  }

  for (const customization of CUSTOMIZATIONS) {
    if (equalsIgnoreCase(customization.appName, appName) || equalsIgnoreCase(customization.appId, appId)) {
      return customization;
    }
  }

  return {};
};