import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { classList } from '../../utils';
import { ReactComponent as CheckIcon } from './images/check.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        padding: "15px 0",
        marginBottom: "30px",
        [theme.breakpoints.up("md")]: {
            padding: 0,
            margin: 0
        }
    },
    stepList: {
        display: "flex",
        width: "100%",
        listStyle: "none",
        margin: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
        "& li": {
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            "&:last-child": {
                marginRight: 0,
            },
            "&, .open &": {
                [theme.breakpoints.up("md")]: {
                    paddingBottom: "25px",
                    margin: 0,
                }

            }
        },
        ".open &": {
            display: "block",
            "& li": {
                margin: "20px 0",
            },
        },
    },
    stepLabel: {
        display: "none",
        color: '#5e6073',
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "16px",
        fontWeight:'600',
        ".active &, .open &": {
            display: "block",
            paddingLeft: "10px",
        },
        ".active &, .complete &": {
            color: '#282B3E',
        },
        [theme.breakpoints.up("md")]: {
            display: "block",
            padding: '6px 0px 10px 10px',
        }
    },
    icon: {
        display: "inline-block",
        width: "15px",
        height: "15px",
        lineHeight: "15px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "500",
        backgroundColor: "#FAFAFA",
        border: "2px solid #EDEDED",
        borderRadius: "50%",
        boxSizing: "border-box",
        "& span": {
            display: "none",
        },
        "& .check": {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            width: "30px",
            height: "30px",
            lineHeight: "26px", // height - borderWidth * 2
            "& span": {
                display: "inline",
            },
        },
        ".open &": {
            width: "30px",
            height: "30px",
            lineHeight: "26px", // height - borderWidth * 2
            "& span": {
                display: "inline",
            },
        },
    },
    iconActive: {
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        border: "none",
        backgroundColor: "#00009A",
        "& span": {
            display: "inline",
            fontSize: "16px",
            color: "#FFFFFF",
        },
        ".open &": {
            lineHeight: "30px",
        },
    },
    iconComplete: {
        backgroundColor: "#282B3E",
        border: "none",
        "& span": {
            display: "none", // override desktop
        },
        "& .check": {
            display: "block",
        },
        ".open & span": {
            display: "none",
        },
    },
    collapseButton: {
        position: "absolute",
        right: 0,
        bottom: "50%",
        transform: "translateY(50%)",
        border: "none",
        backgroundColor: "transparent",
        paddingBottom: "3px", // account for rotation offset
        ".open &": {
            bottom: "15px",
            paddingTop: "3px", // account for rotation offset
            paddingBottom: 0, // account for rotation offset
        },
        "&:before": {
            content: "''",
            display: "inline-block",
            border: "solid #282B3E",
            borderWidth: "0 3px 3px 0",
            padding: "3px",
            transform: "rotate(45deg)",
            transition: "transform 0.5s",
            ".open &": {
                transform: "rotate(-135deg)",
            }
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    }
}));

const StepIcon = ({ stepText, isActive, isComplete }) => {
    const classes = useStyles();
    const className = classList({
        [classes.icon]: true,
        [classes.iconActive]: isActive,
        [classes.iconComplete]: isComplete,
    });

    return (
        <i className={className}>
            <span>{stepText}</span>
            <CheckIcon className="check" />
        </i>
    );
}

const StepItem = ({ step, text, currentStep }) => {
    const classes = useStyles();
    let isActive = currentStep === step;
    let isComplete = currentStep > step;
    if(currentStep === 3 && step === 2){
        isComplete = false;
        isActive = true;
    }
  
    return (
        <li className={isActive ? "active" : (isComplete ? "complete" : null)}>
            <StepIcon stepText={step + 1} isActive={isActive} isComplete={isComplete} />
            <span className={classes.stepLabel}>{text}</span>
        </li>
    );
};

const RegistrationSteps = ({ step }) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles();
    const className = classList({
        [classes.root]: true,
        "open": isOpen,
    });
    return (
        <div className={className}>
            <ul id="step-list" className={classes.stepList}>
                <StepItem step={0} text="Create your profile" currentStep={step} />
                <StepItem step={1} text="Register" currentStep={step} />
                <StepItem step={2} text="Verify your profile" currentStep={step} />
            </ul>
            <button
                className={classes.collapseButton}
                type="button"
                aria-controls="step-list"
                aria-expanded={isOpen}
                onClick={() => setIsOpen(!isOpen)}>
                    <Typography variant="srOnly">{isOpen ? "collapse steps" : "open steps"}</Typography>
            </button>
        </div>
    );
}

export default RegistrationSteps