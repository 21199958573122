import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { getErrorMessage, isEmpty } from '../../utils/Validations';
import { TextInput, ActionButton, Dropdown, DROPDOWN_VARIANT, Icon, Button, Progress, INPUT_VARIANT } from '@manulife/mux';
import { ReactComponent as PasswordVisible } from './images/password_visible.svg';
import { ReactComponent as PasswordHide } from './images/password_hide.svg';
import { PasswordStrengthValidator, PasswordMeter } from '../../components/';
import SecretQuestions from '../../constants/SecretQuestions.json';
import { Left } from '@manulife/mux-cds-icons';
import { validate } from '../../components/PageBody/validate';
import Service from '../../Services/Service';
import Constants from '../../utils/Constants';
import validatePasswordAjax from '../../api/validatePasswordAjax';
import { hasNumbers, hasUpper, hasLower, hasSpecial, hasEight, isValidPasword } from '../../utils/ValidatePassword';
import InputError from './../../components/InputError';
import { Tooltip } from '@manulife/mux';
import { Info1 } from '@manulife/mux-cds-icons';
import { MyContext } from '../../Context/MyContext';




const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    descText: {
        width: "820px",
        height: "272px",
        fontFamily: Constants.FONT_FAMILY,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "22px",
        lineHeight: "34px",
        color: "#282B3E",
    },
    showIcon: {
        display: Constants.CSS_DISPLAY,
        marginLeft: "-25px",
        marginTop: "-20px",
    },
    flexDisplay: {
        display: "flex"
    },
    textFieldStyle:{
        fontWeight:"400",
        fontSize:"16px",
        lineHeight:"26px"        
    }
}))

const RegistrationPage = props => {

    const context = useContext(MyContext);
    const { appId, errorState, setErrorState, setFormValues,systemErrorCount, formValues,setSystemErrorCount, isAwaitingApiCall, setIsAwaitingApiCall, onClick,setSessionTimeoutEnable } = props;
    const classes = useStyles();
    const {setPage, partyData } = props;
    const [tokenInfo, setTokenInfo] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isCPasswordVisible, setCPasswordVisible] = useState(false);
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [isCheckingPassStrength, setCheckingPassStrength] = useState(false);
    const [isPasswordStrengthPassed, setPasswordStrengthPassed] = useState(false);
    const [password, setPassword] = useState("");
    const tooltipText = <div><p>Your username will be used to sign in to John Hancock’s CareGiver application.</p><p></p>
                        <p>Please use only . - _ @ as special characters in the username.</p></div>
    const tooltipText2 = <div><p>Your password will be used to sign in to John Hancock’s CareGiver application.<br/><br/>
                         For security, please check password strength before you proceed.
                         </p></div>

    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key == "Enter") {
            submit();
        }
    };

    function buildDropDownSQA() {
        let questions = SecretQuestions.questions;
        let questionObj = []
        for (let i = 0; i < questions.length; i++) {
            questionObj.push({ value: (i + 1).toString(), label: questions[i].toString() })
        }
        return questionObj;
    }


    const [formErrors, setFormErrors] = useState({
        userName: { id: 'userName', errMsg: '', isError: false },
        pwd: { id: 'pwd', errMsg: '', isError: false },
        confirmPwd: { id: 'confirmPwd', errMsg: '', isError: false },
        securityQuestion: { id: 'securityQuestion', errMsg: '', isError: false },
        answer: { id: 'answer', errMsg: '', isError: false },
        tcChecked: { id: 'tcChecked', errMsg: '', isError: false }
    });

    
    const changeHandler = (name, value) => {
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
        setFormErrorOnChange(name, value);
    }


    function setFormErrorOnChange(name, value) {
        if (formErrors[name].isError != !value || !value) {
            let formError = { id: name, errMsg: getErrorMessage(name), isError: !value };
            setFormErrors({ ...formErrors, [name]: formError });
        }
    }

    function doNothing(){        
    }

    function checkSpecialChar(){     
        let text = formValues.userName;
        let pattern = /^[A-Za-z0-9_@.-]*$/;   
        let result = text.match(pattern);
        //console.log(result);
        if(result !=null){
            return true;
        }else{
            return false;
        }
    }

    function validateUsernameAjaxCall() {
        if (!validateUserName().userName.isError) {            
            const payload = {
                "guid": props.tokenInfo.guid,
                "username": formValues.userName,
                "appId":appId
            };
            setLoading(true);
            Service.validateUsername(payload)
                .then((response) => {
                    setLoading(false);
                    if (response.code === Constants.SUCCESS_CODE_0000) {
                    } else if(response.code === "9069") {
                        let formError = { id: 'userName', errMsg: getErrorMessage("userNameDuplicate"), isError: true };
                        setFormErrors({ ...formErrors, userName: formError })
                        return false;
                    }else {
                        props.onClick(7);
                    }
                });
        }
    }

    function submit() {
        setLoading(true);
        if (validate() && passwordValidated && isPasswordStrengthPassed && !isCheckingPassStrength) {
            setLoading(false);
            props.onClick(1);
        }else{
            setLoading(false);
        }
    }

    
    function validate() {
        let isValid = true;
        let tempFormErrors = {};
        let userNameValidation = validateUserName();
        if(userNameValidation.userName.isError){
            tempFormErrors["userName"] = userNameValidation.userName;
            isValid = false;
        } else if (formErrors.userName.isError) {
            tempFormErrors["userName"] = { id: 'userName', errMsg: getErrorMessage("userNameDuplicate"), isError: true };
            isValid = false;
        }

        if (isEmpty(formValues.pwd)) {
            tempFormErrors["pwd"] = { id: 'pwd', errMsg: getErrorMessage("pwd"), isError: true };
            isValid = false;
        }
        if (isEmpty(formValues.confirmPwd)) {
            tempFormErrors["confirmPwd"] = { id: 'confirmPwd', errMsg: getErrorMessage("confirmPwd"), isError: true };
            isValid = false;
        } else if (formValues.pwd !== formValues.confirmPwd) {
            tempFormErrors["confirmPwd"] = { id: 'confirmPwd', errMsg: getErrorMessage("confirmPwdNotMatched"), isError: true };
            isValid = false;
        }
        if (isEmpty(formValues.securityQuestion)) {
            tempFormErrors["securityQuestion"] = { id: 'securityQuestion', errMsg: getErrorMessage("securityQuestion"), isError: true };
            isValid = false;
        }
        if (isEmpty(formValues.answer)) {
            tempFormErrors["answer"] = { id: 'answer', errMsg: getErrorMessage("answer"), isError: true };
            isValid = false;
        }

        if(isValid){
            onPasswordCheck();
        }        

        setFormErrors({ ...formErrors, ...tempFormErrors })

        return isValid;
    }

    function validateUserName() {
        let error = {};
        if (isEmpty(formValues.userName)) {
            error = { ...formErrors, userName: { id: 'userName', errMsg: getErrorMessage("userName"), isError: true } }
        }
        else if(formValues.userName.length<=8){
            error = { ...formErrors, userName: { id: 'userName', errMsg: getErrorMessage("userNameLen"), isError: true } }
        } else if(!checkSpecialChar()){
            error = { ...formErrors, userName: { id: 'userName', errMsg: getErrorMessage("userNameSpecialChars"), isError: true } }
        }else{
            error = {userName: {isError: false}}
        }
        if(error.userName.isError){
            setFormErrors(error);
        }
        return error

    }


    function onPasswordValidatedResponse(response, error) {
        setCheckingPassStrength(false);
        setPassword(formValues.pwd);
        if (error) {
            //setSystemErrorCount(systemErrorCount + 1);
            props.onClick(7); // Password Validation Service failure
            return;
        }

        let data = response.data;     
        if (data.code === "0000") {
            //setSystemErrorCount(0);
            setPasswordStrengthPassed(true);
            checkAndSetUIPasswordErrors(true); // validatepassword success true            
        } else if (data.code === "9001" || data.code === "9003") {
            //setSystemErrorCount(systemErrorCount + 1);
            setPasswordStrengthPassed(false);
        } else if (data.code === "9020") {
            setPasswordStrengthPassed(false);
            if (isValidPasword(formValues.pwd)) {
                setFormErrors({ ...formErrors, pwd: { id: 'pwd', errMsg: data.message, isError: true} });
                setErrorState({ ...errorState, ...{ pwd: { id: 'pwd', errMsg: data.message, isError: true } } });
            } else {
                checkAndSetUIPasswordErrors(false); // validatepassword success true
            }
        } else if (data.code === "2001" || data.code === "2002" ||
            data.code === "2003" || data.code === "2004") {
            setFormErrors({ ...formErrors, pwd: { id: 'pwd', errMsg: data.message, isError: true } });
            setErrorState({ ...errorState, ...{ pwd: { id: 'pwd', errMsg: data.message, isError: true} } });
            setPasswordStrengthPassed(false);
        }
    }

    function checkAndSetUIPasswordErrors(validPasswordSuccess) {
        let password = formValues.pwd.trim();
        let errors = [];
        errors.push(hasNumbers(password));
        errors.push(hasUpper(password));
        errors.push(hasLower(password));
        errors.push(hasSpecial(password));
        errors.push(hasEight(password));

        errors = errors.filter((item) => item != null);

        if (errors.length > 0) {
            let errorMsg = errors.length == 1 && validPasswordSuccess ? errors[0] : "Please be sure to fulfill all password requirements.";
            setFormErrors({ ...formErrors, pwd: { id: 'pwd', errMsg: errorMsg, isError: true } });
            setErrorState({ ...errorState, ...{ password: { id: 'pwd', errMsg: errorMsg, isError: true } } });
        }
    }

    function onPasswordCheck() {
        let password = formValues.pwd;  
        if (!password) {
            setPassword(password);
            return;
        }
        if(!isCheckingPassStrength){
            let payload = {
                appId:appId,
                userid: formValues.userName.trim(),
                password: password.trim(),
                firstname: props.tokenInfo.providerfn.trim(),
                lastname: props.tokenInfo.providerln.trim()
            }
            setPasswordValidated(isValidPasword(password))
            setCheckingPassStrength(true)
            validatePasswordAjax(payload, onPasswordValidatedResponse);
        }        
    }

    return (
        <div className={classes.root} data-testid="registrationpage-root">
            <Grid container>
                <Grid item md={12} xs={12}>                    
                    <div className={classes.flexDisplay}>
                        <span><label className={classes.labelStyle}>Username</label></span>
                        <span>
                        <Tooltip title={tooltipText} bubbleWidth={290}
                            customStyle={{
                                rootStyle: {
                                    paddingLeft: "5px",
                                    marginTop: "0px"
                                }
                            }}
                        ><Info1 color="#282B3E" />
                        </Tooltip></span>
                    </div>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="userName"
                        maxLength={50}
                        value={formValues.userName}
                        required={true}
                        placeholder="Choose a username"
                        onChange={e => changeHandler("userName", e)}
                        errors={[formErrors.userName]}
                        onBlur={() => {
                            if((formValues.userName)){
                                validateUsernameAjaxCall()
                            }
                        }}
                        onKeyDown={()=> doNothing()}
                        callouts={true}
                        customStyle={{
                            rootStyle: {
                                width: "70%",
                            },
                            inputStyle: {
                                padding: '0px'

                            }
                        }} name="userName"
                    />
                    {(formErrors.userName.isError) && <InputError inputId="userName" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>                    
                    <div className={classes.flexDisplay}>
                        <span><label className={classes.labelStyle}>Password</label></span>
                        <span>
                        <Tooltip title={tooltipText2} bubbleWidth={290}
                            customStyle={{
                                rootStyle: {
                                    paddingLeft: "5px",
                                    marginTop: "0px"
                                }
                            }}
                        ><Info1 color="#282B3E" />
                        </Tooltip></span>
                    </div>
                    <TextInput
                        variant={INPUT_VARIANT.BASIC_LINE}
                        id="pwd"
                        value={formValues.pwd}
                        placeholder="Pick a password"
                        callouts={true}
                        errors={[formErrors.pwd]}
                        type={isPasswordVisible ? "text" : "password"}
                        isIconClickable={true}
                        maxLength={50}
                        onKeyDown={()=> doNothing()}
                        onChange={e => {
                            if (passwordValidated) { 
                                setPasswordValidated(false); 
                            }
                            changeHandler("pwd", e)
                            }
                        }
                        onBlur={onPasswordCheck}
                        onFocus={()=> {
                            setPasswordValidated(false);
                            setErrorState({...errorState, ...{pwd: { id: 'pwd', msg: "", isError: false }}});
                        }}                        
                        icon={<ActionButton
                            ariaLabel="Edit"
                            icon={isPasswordVisible ? <PasswordVisible /> : <PasswordHide />}
                            onClick={e => setPasswordVisible(!isPasswordVisible)}
                        />}
                        customStyle={{
                            rootStyle: {
                                width: "70%",                              
                            },
                            inputStyle: {
                                padding: "0px"                               
                            },
                        }}
                        name="pwd"
                    /> 
                    {(formErrors.pwd.isError) && <InputError inputId="pwd" formErrors={formErrors} /> }                   
                </Grid>
                <Grid item className={classes.gridSeperation} xs={12}>
                    <PasswordStrengthValidator 
                        pwd={password} 
                        onPasswordCheck={onPasswordCheck}
                        formErrors={formErrors}
                        errorState={errorState} 
                        isCheckingPassStrength={isCheckingPassStrength} 
                        passwordValidated={passwordValidated}
                        isPasswordStrengthPassed={isPasswordStrengthPassed}
                    />
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>Confirm your password</label>
                    <TextInput
                        variant={INPUT_VARIANT.BASIC_LINE}
                        id="confirmPwd"
                        value={formValues.confirmPwd}
                        placeholder="Re-enter password"
                        type={isCPasswordVisible ? "text" : "password"}
                        isIconClickable={true}
                        maxLength={50}
                        callouts={true}
                        errors={[formErrors.confirmPwd]}
                        onKeyDown={()=> doNothing()}
                        onChange={(e) => changeHandler("confirmPwd", e)}
                        icon={<ActionButton
                            ariaLabel="Edit"
                            icon={isCPasswordVisible ? <PasswordVisible /> : <PasswordHide />}
                            onClick={e => setCPasswordVisible(!isCPasswordVisible)}
                        />}
                        customStyle={{
                            rootStyle: {
                                width: "70%",                                
                            },
                            inputStyle: {
                                padding: "0px",                               
                            },
                        }}
                        name="confirmPwd"
                    />
                    {(formErrors.confirmPwd.isError) && <InputError inputId="confirmPwd" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>Security question</label>
                    <Grid item xs={12}></Grid>
                    <Dropdown
                        id="securityQuestion"
                        value={formValues.securityQuestion}
                        ariaRequired={true}
                        onChange={e => changeHandler("securityQuestion", e)}
                        labelStyle={{
                            rootStyle: {
                                paddingLeft: "500px",
                            }
                        }}
                        callouts={true}
                        variant={DROPDOWN_VARIANT.BASIC_LINE}
                        errors={[formErrors.securityQuestion]}
                        dropdownItems={buildDropDownSQA()}
                        placeholder="Choose your question"
                        customStyle={{
                            rootStyle: {
                                width: "70%",
                                paddingRight: "0px",
                                paddingLeft: "0px",
                                padding: "0px",
                            }
                        }}
                        name="securityQuestion"
                    />
                     {(formErrors.securityQuestion.isError) && <InputError inputId="securityQuestion" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>
                        Answer
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="answer"
                        maxLength={50}
                        value={formValues.answer}
                        placeholder="Enter security question answer"
                        onChange={e => changeHandler("answer", e)}
                        callouts={true}
                        errors={[formErrors.answer]}
                        onKeyDown={()=> doNothing()}
                        customStyle={{
                            rootStyle: {
                                width: "70%",
                            },
                            inputStyle: {
                                padding: '0px'

                            }
                        }} name="answer"
                    />
                   {(formErrors.answer.isError) && <InputError inputId="answer" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Button
                    name="nextButton"
                    data-testid="registration-submitButton"
                    id="nextButton"
                    onClick={e => submit()}
                    customStyle={{
                        buttonStyle: {
                            width: '10%',
                            height: '5%',
                        }
                    }}
                    disabled={isLoading}> Next
                </Button>
                {formValues.isLoading && <div className="registration-progress-container">
                    <Progress
                        data-testid="registration-loader"
                        isFloating={false} customStyle={{
                            spinnerStyle: {
                                position: "relative",
                                height: "40px",
                                width: "40px"
                            }
                        }} />
                    <p className="registration-loading-text">Please wait while we process your information</p>
                </div>
                }
                <Grid item xs={12} className={classes.gridSeparation}>
                    <label className={classes.labelStyle}></label>
                </Grid>
            </Grid>
        </div>
    );
};

export default RegistrationPage;