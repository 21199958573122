import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles"
import Grid from '@mui/material/Grid';
import {Button, INPUT_VARIANT, Progress, TextInput} from '@manulife/mux';
import { useState } from 'react';
import { isEmpty, getErrorMessage } from '../../utils/Validations';
import { MyContext } from '../../Context/MyContext';
import Service from '../../Services/Service';
import Constants from '../../utils/Constants';
import Codes from '../../constants/Codes.json';
import { GifOutlined } from '@material-ui/icons';
import "./../../style/MyNotificationComponentStyle.css"
import InputError from './../InputError';




const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontFamily: Constants.FONT_FAMILY,
        fontWeight: "600",
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "24px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    nameStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "26px"
    },
    sublabel: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "14px",
        color: "#282B3E",
        lineHeight: "20px"
    },
    resendText: {
        fontFamily: Constants.FONT_FAMILY,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "26px",
        textDecorationLine: "underline",
        color: "#282B3E",
        cursor: "pointer"
    },
    buttonWrapper: {
        [theme.breakpoints.only("sm")]: {
            display: "flex"
        },
        [theme.breakpoints.only('xl')]: {
            display: "flex"
        },
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: "1rem",
            [theme.breakpoints.up("sm")]: {
                display: "unset",
                width: "auto",
                marginBottom: 0,
                marginLeft: "1.5rem",
                "&:first-child": {
                    marginLeft: 0
                }
            }
        }
    },
    errorLabelStyle: {
        color: "#C14A36 !important",
        fontWeight: "300px"
    },
    inlineDisplay: {
        [theme.breakpoints.only('xs')]: {
            justifyContent: "center",
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: "82.5px"
        },
        [theme.breakpoints.only('lg')]: {
            marginLeft: "82.5px"
        },
        display: "flex",
        height: "100%",
        marginLeft: "8px",
    },
    inlineDisplay2: {
        display: "inline-block",
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: "inline-block",
        marginLeft: "8px",


    }
}))

const MfaVerificationComponent = (props) => {
    const context = useContext(MyContext);
    const { appId, errorState, setErrorState,setFormValues,formValues, systemErrorCount, setSystemErrorCount, isAwaitingApiCall, setIsAwaitingApiCall, onClick} = props;
    const classes = useStyles();
   
    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })

    const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL ? process.env.REACT_APP_REDIRECT_URL : "http://localhost:3001/?appid=266977&jwt=";// "https://provider.registration-uat.johnhancock.com/backend"; 


    function doNothing(){        
    }


    const [formErrors, setFormErrors] = useState({
        mfaCode: { id: 'mfaCode', errMsg: '', isError: false }
    });

    const [isLoading, setLoading] = useState(false);

    function changeHandler(name, value) {        
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
        setFormErrorOnChange(name, value);
    }
    
    function setFormErrorOnChange(name, value){
        if(formErrors[name].isError != !value ){
            console.log("setFormErrorOnChange called");
            let formError = {id: name, errMsg: getErrorMessage(name), isError: !value };
            setFormErrors({...formErrors, [name]: formError});            
        }
    }

    function submit(){
        if(validate()){
            verifyMfaCode(); 
        }
    }
    
    function resendMfaCode(){
        setErrorState({ ...errorState, ...{ mfaCode: { id: 'mfaCode', errMsg: "", isError: false } } }); 
        let formError = { id: 'mfaCode', errMsg: "", isError: false };
        setFormErrors({ ...formErrors, mfaCode: formError });                 
        setLoading(true);
        let phonenumber = formValues.phoneNumber.trim().replace(/[^a-zA-Z0-9 ]/g, '');
        phonenumber = phonenumber.replaceAll(' ','');
        const payload = {
            "guid": props.tokenInfo.ecryptGuid.trim(),
            "phonenumber": phonenumber,
            "type": formValues.mfaType.trim(),
            "appid" : Constants.APP_ID,
            "appId" : Constants.APP_ID             
        };
        setFormValues({ ...formValues, "mfaCode": "" });        
        Service.generateMfaCodeCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code < Constants.ERROR_CODE){
                props.onClick(3);               
            } else if(response.code === "9052"){
                props.onClick(7);    
            }else{
                let message = Codes.message[response.code] || Codes.message.something_went_wrong;
                setErrorState({ ...errorState, ...{ mfaType: { id: 'mfaType', errMsg: message, isError: true } } });                
            }
        }).catch(error => {
            setLoading(false);
            console.log("error sendMfaCode ========>", error);
            if (error.message === "Network Error") {
            }
            props.onClick(7);     
          
        });
    }

    function validate(){        
        let isValid = true;   
        if(isEmpty(formValues.mfaCode)){
            let formError = {id: 'mfaCode', errMsg: "Please enter code", isError: true };
            setFormErrors({...formErrors, mfaCode: formError})
            isValid = false;
        }
        return isValid;
    }

    function verifyMfaCode(){
        setLoading(true);
        let phonenumber = formValues.phoneNumber.trim().replace(/[^a-zA-Z0-9 ]/g, '');
        phonenumber = phonenumber.replaceAll(' ','');
        const payload = {
            "guid": props.tokenInfo.ecryptGuid.trim(),
            "phonenumber": phonenumber,
            "code": formValues.mfaCode.trim(),
            "appid" : Constants.APP_ID,
            "appId" : Constants.APP_ID                           
        };
        Service.validateMfaCodeCall(payload)
        .then((response)=> {
            if(response.code === "4000"){
                //ReDirect to congratulations pages
                setLoading(false);
                props.onClick(5);
            } else{
                let message = Codes.message[response.code] || Codes.message.something_went_wrong;
                if(response.code==="5000"){ // Redirect User when LN Phone validation failed
                    //props.onClick(13);                   
                    let res = JSON.parse(response.details);
                    console.log("url-->"+res.redirectURL+res.jwt);
                    window.location.href = res.redirectURL+res.jwt;
                }
                else if(response.code==="9004"){
                    //Enter old Code and try again
                   setLoading(false);
                   let formError = { id: 'mfaCode', errMsg: message || Codes.message.something_went_wrong, isError: true };
                   formValues.mfaCode = "";
                   setFormErrors({ ...formErrors, mfaCode: formError });                       
                   return false;
                }else if(response.code==="9000" || response.code==="9033"){
                    //Exceeded MFA attempts redirect to error page.
                   setLoading(false);
                   props.onClick(8); 
                }
                else if(response.code==="9023"){
                    //ReDirect to Try Again page
                   setLoading(false);
                   props.onClick(9); 
                } else if(response.code==="5004"){
                    //display call 1800 page when CIAM Verification UI is down
                   props.setPage(14);
                } else{
                    //ReDirect to Try again page
                    //9015 : ISAM Service Failure
                    //9017: ISAM database saved record failed 
                    //9999 : Generic ISAM Service Failure 
                    //5001 : Sent email service failure
                    //5002 : Send congratulations email FAILED, Did not saved in JHIAMBD
                    //9067 : Save ISAM or JHIAMDB Failed  
                    setLoading(false);
                    props.onClick(7);      
                }
            }
        }).catch(error => {
            setLoading(false);
            console.log("link validate Error ========>", error);
            if (error.message === "Network Error") {
            }
            props.onClick(7);     
          
        });
    }
    
    return (
        <div className={classes.root}>
            <Grid data-testid="mfaType-root" container md={12} xs={12} spacing={0}>

                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>Please enter the code you received:</label>
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>                
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id='mfaCode'
                        data-testid="mfaVerification-mfaCode"
                        placeholder="Enter code"
                        maxLength={50}
                        value={formValues.mfaCode}
                        errors={[formErrors.mfaCode]}
                        onKeyDown={()=> doNothing()}
                        onChange={e => changeHandler("mfaCode", e)}                        
                        callouts={true}
                        customStyle={{
                            rootStyle: {
                                width: "50%",
                            },
                            inputStyle: {
                                padding: '0px'

                            }
                        }} name="mfaCode"
                    />
                    {(formErrors.mfaCode.isError) && <InputError inputId="mfaCode" formErrors={formErrors} /> }
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>

                <Grid item xs={12} className={classes.buttonWrapper}>
                    <Button
                        customStyle={{
                            buttonStyle: {
                                height: "60px",
                                minWidth: "200px",
                            }
                        }}
                        disabled={isLoading}
                        id="submitButton"
                        name="submitButton"
                        onClick={e => submit()}>
                            Submit
                    </Button>
                    <Button
                        variant="secondary"
                        customStyle={{
                            buttonStyle: {
                                height: "60px",
                                minWidth: "200px",
                                color: "#282B3E"                                
                            }
                        }}
                        id="resendButton"
                        onClick={() => resendMfaCode()}
                        name="resendButton"
                        disabled={isLoading}
                        >Get a new code
                    </Button>                   
                </Grid>
                <Grid item md={12} xs={12}>
                    {isLoading && <div className="progress-container">
                                <Progress                             
                                    data-testid="mfaType-loader"
                                    isFloating={false} customStyle={{
                                    spinnerStyle: {
                                        position: "relative",
                                        height: "40px",
                                        width: "40px"
                                    }
                                }} />
                                <p className="loading-text">Please wait while we process your information</p>
                            </div>
                    }
                </Grid>

            </Grid>
        </div>
    )
}
export default MfaVerificationComponent;