import React, { useState,useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import { TextInput, INPUT_VARIANT,Button,Progress } from '@manulife/mux';
import { getErrorMessage, isEmpty } from '../../utils/Validations';
import Constants from '../../utils/Constants';
import Service from '../../Services/Service';
import InputError from './../../components/InputError';
import { MyContext } from '../../Context/MyContext';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    nameStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        color: "#282B3E",
        lineHeight: "26px"
    },
    sublabel: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "14px",
        color: "#282B3E",
        lineHeight: "20px"
    },
}))

const PhoneValidationPage = props => {

    const context = useContext(MyContext);
    const { appId, errorState, setErrorState,setFormValues,formValues, systemErrorCount, setSystemErrorCount, isAwaitingApiCall, setIsAwaitingApiCall, onClick} = props;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [tokenInfo, setTokenInfo] = useState({});

    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })

    const changeHandler = (name, value) => {
        const newFormValues = {...formValues, [name]: value };
        setFormValues(newFormValues);       
    }

    
    const [formErrors, setFormErrors] = useState({
        phoneNumber: { id: 'phoneNumber', errMsg: '', isError: false }
    });

    function doNothing(){        
    }

    function submitRegister(){      
        let isValid = true;
        
        let tempFormErrors = {};  
        if (isEmpty(formValues.phoneNumber) || formValues.phoneNumber.toString().trim().length!=14) {
            tempFormErrors["phoneNumber"] = { id: 'phoneNumber', errMsg: getErrorMessage("phoneNumber"), isError: true };
            isValid = false;
            setFormErrors({ ...formErrors, ...tempFormErrors })
        }
        if(isValid){ 
            let phonenumber = formValues.phoneNumber.trim().replace(/[^a-zA-Z0-9 ]/g, '');
            phonenumber = phonenumber.replaceAll(' ','');
            
            setLoading(true);
            const payload = {
                "appid" : Constants.APP_ID,
                "appId" : Constants.APP_ID,
                "phonenumber": phonenumber.trim(),
                "firstname": props.tokenInfo.providerfn.trim(),
                "lastname": props.tokenInfo.providerln.trim(),
                "username": formValues.userName.trim(),
                "password": formValues.pwd.trim(),
                "sqa":  formValues.securityQuestion.trim()+":"+formValues.answer.trim(),
                "guid": props.tokenInfo.guid.trim(),
                "phonenumberlist" : props.tokenInfo.phonenumberlist.trim()
            };
            Service.register(payload)
                .then((response) => {
                    setLoading(false);                    
                    if (response.code === "3000" || response.code==="9066"){
                        props.onClick(2);
                    } else if (response.code ==="9065"){
                        let formError = { id: 'phoneNumber', errMsg: response.message != null ? response.message :getErrorMessage("icorrectPhoneNumber"), isError: true };
                        setFormErrors({ ...formErrors, phoneNumber: formError });
                    } else {
                        let formError = { id: 'phoneNumber', errMsg: response.message != null ? response.message :getErrorMessage("LNPhoneNumberFailed"), isError: true };
                        setFormErrors({ ...formErrors, phoneNumber: formError });                       
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log("error LexisNexis Service failure ========>", error);
                    if (error.message === "Network Error") {
                    }
                    let formError = { id: 'phoneNumber', errMsg: getErrorMessage("LNPhoneNumberFailed"), isError: true };
                        setFormErrors({ ...formErrors, phoneNumber: formError });                       
                        return false;                      
                });
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>Your first name and last name</label>                                        
                </Grid>
                <Grid item md={12} xs={12}>
                <label className={classes.nameStyle}>{props.tokenInfo.providerfn} {props.tokenInfo.providerln}</label>  
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <Grid item md={12} xs={12}>
                    <label className={classes.labelStyle}>
                        Your mobile number
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id='phoneNumber'
                        placeholder="(XXX)-XXX-XXXX"
                        mask="(999)-999-9999"
                        value={formValues.phoneNumber}
                        required="true"
                        errors={[formErrors.phoneNumber]}
                        onChange={e => changeHandler("phoneNumber", e)}
                        onKeyDown={()=> doNothing()}
                        onFocus={()=> {                            
                            setErrorState({...errorState, ...{phoneNumber: { id: 'phoneNumber', errMsg: "", isError: false }}});
                        }}
                        callouts={true}
                        //errors={[formErrors.phoneNumber]}
                        customStyle={{
                            rootStyle: {
                                width: "440px",
                            },
                            inputStyle: {
                                padding: '0px'

                            }
                        }} name="phoneNumber" />
                    {(formErrors.phoneNumber.isError) && <InputError inputId="phoneNumber" formErrors={formErrors} /> }
                    <label className={classes.sublabel}>
                    This number will be used for text/call verification.
                    </label>
                </Grid>
                <Grid item xs={12} className={classes.gridSeparation}></Grid>  
                <Button
                        name="registerButton"
                        data-testid="registration-submitButton"                        
                        id="registerButton"                        
                        onClick={e => submitRegister()}                        
                        customStyle={{
                            buttonStyle: {
                                width: '150px',
                                height:'5%', 
                            }
                        }}
                        disabled={isLoading}> Register                       
                </Button>
                    {isLoading && <div className="progress-container">
                                <Progress                             
                                    data-testid="mfaType-loader"
                                    isFloating={false} customStyle={{
                                    spinnerStyle: {
                                        position: "relative",
                                        height: "40px",
                                        width: "40px"
                                    }
                                }} />
                                <p className="loading-text">Please wait while we process your information</p>
                            </div>
                    }
            </Grid>
        </div>
    );
};

export default PhoneValidationPage;