import React, { useState } from 'react';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { THEMES } from '@manulife/mux';
import { PageBody, Header, Footer } from './components'
import { Grid, Container } from '@material-ui/core';
import { MyContext } from './Context/MyContext';


function App(props) {

  const [notification, setNotification] = useState({
    isVisible: false,
    type: "",
    title: "",
    message: ""
  });
  const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(true);

  function iniFrame() {
    if (window.self !== window.top) {
      console.log("In iFrame");
      return true;
    } else {
      console.log("Not in iFrame");
      return false;
    }
  }
  if (iniFrame()) {
    return null
  }


  return (
    <ThemeProvider theme={THEMES.johnhancockTheme}>
      <MyContext.Provider value={{ notification, setNotification, sessionTimeoutEnable, setSessionTimeoutEnable }}>
        <Container className="app-content">
          <Grid container spacing={8} data-testid="app-root-js">
            <Grid item xs={8}>
              <Header />
            </Grid>
          </Grid>
          <PageBody page={0} />
        </Container>
        <Footer />
      </MyContext.Provider>
    </ThemeProvider>
  );
}

export default App;
