import React from 'react'
import Icon from '@mui/material/Icon';
import { Progress } from '@manulife/mux';
import Grid from '@mui/material/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from "@material-ui/core/styles";
import { hasNumbers, hasUpper, hasLower, hasSpecial, noRepeat, hasEight } from '../../utils/ValidatePassword';
import "../../style/PasswordStrengthValidator.css"
import InputError from '../InputError';

const font = "\"Manulife JH Sans\"";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
    },
    strengthContainer: {
        position: "relative",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "-19px",
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
    },
    inlineDisplay2: {
        display: "inline-block"
    },
    strengthText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#0000C1",
        cursor: "pointer"
    },   
    strengthCheckingText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        marginTop: "10px",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#5E6073"
    } ,
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "0.5em 0px 10px"
    },
    strengthCheckPassed: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "32px",
        color: "#06874E"
    },
    listRoot: {
        display: 'block',
        marginTop: "20px",
        '& ul': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            whiteSpace: 'nowrap',
            '& ul': {
                flexGrow: 0,
                flexShrink: 1,
                '&:last-child li': {
                    paddingLeft: '2rem'
                }
            }
        }
    },
    iconStylesGrey: {
        fontSize: '14px',
        color: '#5E6073',
        paddingRight: '2px',
        marginRight: '5px'
    },
    iconStylesGreen: {
        fontSize: '14px',
        color: '#00A758',
        paddingRight: '2px',
        marginRight: '5px'
    },
    iconStylesRed: {
        fontSize: '14px',
        color: '#C14A36',
        paddingRight: '2px',
        marginRight: '5px'
    },
    passwordValidityTextStylesGrey: {
        fontFamily: font,
        fontSize: "14px",
        color: '#5E6073'
    },
    passwordValidityTextStylesGreen: {
        fontFamily: font,
        fontSize: "14px",
        color: '#00A758'
    },
    passwordValidityTextStylesRed: {
        fontFamily: font,
        fontSize: "14px",
        color: '#C14A36'
    }
}));

const PasswordStrengthItem = ({ pwd, text, assertion, isPasswordStrengthPassed }) => {
    const classes = useStyles();
    const isValid = assertion(pwd);
    return (
        <li>
            {(isPasswordStrengthPassed == undefined && isValid) || (isPasswordStrengthPassed)
                ? <CheckIcon className={classes.iconStylesGrey} />
                : <ClearIcon className={classes.iconStylesGrey} />}
            <span className={classes.passwordValidityTextStylesGrey}>
                {text}
            </span>
        </li>
    );
}


const PasswordStrengthValidator = props => {
    
    const { pwd, onPasswordCheck, formErrors,errorState, isCheckingPassStrength, passwordValidated, isPasswordStrengthPassed } = props;

    return (
        <div className="root">
            <Grid item md={12} xs={12} className="strengthContainer">
                    {
                        (!isCheckingPassStrength && passwordValidated && isPasswordStrengthPassed) &&
                            <span onClick={onPasswordCheck} className="strengthCheckPassed">Password strength check passed!</span>
                    }
                    {
                        isCheckingPassStrength ?
                            <span onClick={onPasswordCheck} className="strengthCheckingText">Password strength - Checking...</span>
                            :
                            !(passwordValidated && isPasswordStrengthPassed) && (errorState?.pwd?.isError && errorState?.pwd?.msg) ?                            
                                <InputError inputId="pwd" formErrors={formErrors} />
                            
                            : !(passwordValidated && isPasswordStrengthPassed) &&
                            <span onClick={onPasswordCheck} className="strengthText">Check password strength</span>
                    }
                    {
                        isCheckingPassStrength &&
                        <div className="inlineDisplay">
                            <div className="inlineDisplay2">
                                <Progress
                                    size={30}
                                    isFloating={false}
                                    color="#0000C1"
                                    customStyle={{
                                        spinnerStyle: {
                                            position: "relative",
                                            height: "30px",
                                            width: "30px",
                                            marginTop: "10px"
                                        }
                                    }}/>
                            </div>
                        </div>
                    }
            </Grid>
            <div className="listRoot">
                <ul>
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="Eight characters minimum"
                        assertion={(pwd)=> !hasEight(pwd)}
                        />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One number"
                        assertion={(pwd)=> !hasNumbers(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One special character"
                        assertion={(pwd)=> !hasSpecial(pwd)}
                         />
                </ul>
                <ul>
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One lowercase letter"
                        assertion={(pwd)=> !hasLower(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One uppercase letter"
                        assertion={(pwd)=> !hasUpper(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="No blocked words or sequences"
                        assertion={(pwd)=> !noRepeat(pwd)}
                        isPasswordStrengthPassed={isPasswordStrengthPassed}
                         />
                </ul>
            </div>
        </div>
    );
}

export default PasswordStrengthValidator