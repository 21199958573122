import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Greybar } from './Greybar.svg';
import { ReactComponent as Redbar } from './Redbar.svg';
import { ReactComponent as Greenbar } from './Greenbar.svg';

const zxcvbn = require('zxcvbn');
const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
    rowPadding: {
        paddingTop: "10px"
    },
    progressSeperation: {
        paddingBottom: "5px",
        paddingRight: "7px"
    },
    greyWeakTextStyle: {
        fontFamily: font,
        fontSize: "16px",
        color: '#5E6073'
    },
    redWeakTextStyle: {
        fontFamily: font,
        fontSize: "16px",
        color: '#C14A36'
    },
    greenStrongTextStyle: {
        fontFamily: font,
        fontSize: "16px",
        color: '#00A758'
    }
})
)

const PasswordMeter = props => {

    const classes = useStyles()

    const password = props.password
    const result = zxcvbn(password)
    const score = result.score
    const passwordValidated = props.passwordValidated

    const emptyOrUnvalidatedPassword = (password === "" || passwordValidated === false);
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={4} className={classes.progressSeperation} >
                    {emptyOrUnvalidatedPassword ? <Greybar /> : [score <= 1 ? <Redbar /> : <Greenbar />]}
                </Grid>
                <Grid item xs={4} className={classes.progressSeperation}>
                    {emptyOrUnvalidatedPassword ? <Greybar /> : [score >= 2 ? <Greenbar /> : <Redbar />]}
                </Grid>
                <Grid item xs={4} className={classes.progressSeperation}>
                    {emptyOrUnvalidatedPassword ? <Greybar /> : [score === 4 ? <Greenbar /> : [score >= 2 ? <Greybar /> : <Redbar />]]}
                </Grid>
                <Grid item xs={12}>
                    {emptyOrUnvalidatedPassword ? <label className={classes.greyWeakTextStyle} >Password Strength - Weak</label> : [
                        score > 1 && score <= 3 ? <label className={classes.greenStrongTextStyle} >Password Strength - Good</label> : [
                            score === 4 ? <label className={classes.greenStrongTextStyle} >Password Strength - Strong</label>
                                : <label className={classes.redWeakTextStyle} >Password Strength - Weak avoid common words and repetitive characters</label>
                        ]]}
                </Grid>
            </Grid>
        </div>
    )
}

export default PasswordMeter