import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%"
    },
    disclosureStyle: {
        fontWeight: "400",
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "13x",
        lineHeight:"16px",
        width: "100%",
        display: "block",
        color: "#282B3E",
        fontStyle:"normal"
    },
    gridSeparation: {
        paddingTop: "80px",
    },    
    linkcolor:{
        color:"#282B3E",
        order:"0",
        flexGrow:"0",
        fontSize:"15px",
        fontFamily: "\"Manulife JH Sans\"",
        lineHeight:"26px",
        fontWeight:"600",
        fontStyle:"normal"      
    }

}));

const MFAFooter = () => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item md={12} xs={12}>
            <div className={classes.disclosureStyle}>  
                <br/>
                <br/>
                <br/>
                <br/>                                        
                <p>There is no charge from John Hancock, but message and data rates may apply.</p>
                <p>Delivery of the security code may be delayed due to: service outages affecting your telephone device, wireless or internet provider; technology failures; system capacity limitations and other connection issues you or your device may be experiencing.</p>
                <p>If you have any questions or need assistance, please call <a className={classes.linkcolor} href="tel:844-798-3001">844-798-3001</a> to contact us. </p>
                <p>Note: Please be aware that anyone with access to your email account or telephone device may be able to log in into your profile and view your information.</p>
                <p>We value your privacy. For details about our Privacy Policy, <a className={classes.linkcolor} target="_blank" href="https://www.johnhancock.com/privacy-security.html">click here</a>.</p>
                <p>Access and use of this website is for authorized users only. If you have any questions regarding your profile, please contact John Hancock at 844-798-3001.</p>
            </div>   
            </Grid>
        </Grid>
    );
};

export default MFAFooter;
