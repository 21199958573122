import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Info1} from '@manulife/mux-cds-icons';
import { Grid } from '@material-ui/core';
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { TextInput, INPUT_VARIANT, Button, ActionButton } from '@manulife/mux';
import { Tooltip } from '@manulife/mux';
import validate from '../../components/PageBody/validate'
import Constants from '../../utils/Constants';


const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "600",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    subText: {
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "28px"
    },
    InLine: {
        display: Constants.CSS_DISPLAY,
        width: "100%"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    showIcon: {
        display: Constants.CSS_DISPLAY,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "13px",
        width: "100%",
        display: "block",
        color: "#282B3E",
        lineHeight: "20px"
    },
    headerMargin: {
        marginTop: "49px"
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    signInUnderline: {
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000C1",
        lineHeight: "28px",
        fontStyle: "normal"
    },
    inlinePadding: {
        paddingRight: "6em"
    },
    footnoteText: {
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        fontWeight: "normal",
        color: "#282B3E",
        lineHeight: "24px",
        fontStyle: "normal"
    },
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        margin: "0px 0px 1.5em"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px",
        width: "64%",
        float: "right"
    },
    inlineDisplay2: {
        display: Constants.CSS_DISPLAY,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: Constants.CSS_DISPLAY,
        marginLeft: "8px"
    },
    linkStyles: {
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000c1",
        textUnderlinePosition: "under"
    },
    flexDisplay: {
        display: "flex"
    }
}));

const AuthenticationPage = props => {

    const classes = useStyles();
    const tooltipText = "You will use this User ID as your log in for additional accounts you may have for Life Insurance, Long-Term Care, Vitality, and/or Mutual Fund IRA/Roth IRA.";
    

    const [allValues, setAllValues] = useState({
        passwordVisible: false
    });
    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
    }

    const changeHandler = (name, value) => {
        const newFormValues = { ...props.formValues, [name]: value };
        props.setFormValues(newFormValues)
        props.setErrorState(validate(newFormValues, name, props.errorState));
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridSeparation}>
                <h1 className={classes.headerText}>Registration</h1>
            </Grid>
            

            <Grid item xs={12} className={classes.gridSeparation}>
                <span className={classes.subText}>We found existing account(s) with John Hancock.</span>

            </Grid>


            <Grid item xs={12} className={classes.gridSeparation}>
                <span className={classes.subText}>Log in to an existing account to finish registering.</span>

            </Grid>
            <Grid item xs={12} md={9} className={classes.flexDisplay}>
                <span className={classes.subheaderStyles}>This User ID will become your log in for other accounts.</span>
                <Tooltip title={tooltipText} bubbleWidth={200}
                    customStyle={{
                        rootStyle: {
                            paddingLeft: "5px"
                        }
                    }}
                ><Info1 color="#282B3E"/>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} className={classes.headerMargin}>
                <Grid item md={9} xs={12} className={classes.gridSeparation}>
                    <label className={classes.labelStyle} >
                        User ID
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="authUserId"
                        value={props.formValues.authUserId}
                        onChange={e => changeHandler("authUserId", e)}
                        errors={[props.errorState.authUserId]}
                        required={true}
                        placeholder="Enter your User ID"
                        customStyle={{
                            rootStyle: {
                                width: "100%",

                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }}
                        name="authUserId" />

                </Grid>
                <Grid item md={9} xs={12}>
                    <label className={classes.labelStyle} >
                        Password
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="authPassword"
                        type={allValues.passwordVisible ? "text" : "password"}
                        required={true}
                        placeholder="Enter your password"
                        value={props.formValues.authPassword}
                        className={classes.InLine}
                        onChange={e => changeHandler("authPassword", e)}
                        errors={[props.errorState.authPassword]}
                        customStyle={{
                            rootStyle: {
                                width: "100%",

                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }}
                        name="authPassword" />

                    <ActionButton
                        ariaLabel="Edit"
                        icon={allValues.passwordVisible ? <Eye1 color="#282B3E" />
                            : <Eye2 color="#282B3E" />
                        }
                        onClick={e => handleShow("passwordVisible")}
                        customStyle={{
                            buttonStyle: {
                                marginTop: "-45px",
                                marginLeft: "93%",
                                paddingBottom: "20px"
                            }
                        }}
                    />
                    {(props.errorState.authPassword.msg && props.errorState.authPassword.msg !== "") && <div className={classes.errorPasswordLabel}>
                        {props.errorState.authPassword.msg}
                    </div>}

                </Grid>
                <Grid item xs={12}>
                    <Button
                        customStyle={{
                            buttonStyle: {
                                width: "148px",
                                height: "60px"
                            }
                        }}
                        name="AuthenticateButton"
                        id="AuthenticateButton"
                        onClick={e => props.onClick(4)}
                        disabled={props.isAwaitingApiCall}
                    ><div className={classes.buttonTextStyles}>Log In</div></Button>
                </Grid>
                <Grid item md={9} xs={12} style={{ marginTop: "40px" }}>
                    <span className={classes.footnoteText}><b>Please note:</b> If you have forgotten your account or password, you can recover them via 'Sign in' on <a className={classes.linkStyles} href="https://johnhancock.com">JohnHancock.com</a></span>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AuthenticationPage;