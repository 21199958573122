import React, { useState, useEffect, useContext } from 'react';
import {CongratulationsPage, ErrorPage, PhoneValidationPage, RegistrationPage } from '../../pages';
import  RegistrationColumnLayout from '../RegistrationColumnLayout';
import {getQueryParams } from '../../utils';
import Service from '../../Services/Service';
import Constants from '../../constants/Constants';
import MfaTypeComponent from '../MFA/MfaTypeComponent';
import MfaVerificationComponent from '../MFA/MfaVerificationComponent';
import LoaderComponent from './../LoaderComponent';
import MyNotification from './../MyNotificationComponent';
import StartOverComponent from './../../components/StartOverComponent'
import { makeStyles } from '@material-ui/core/styles';
import { MyContext } from '../../Context/MyContext';
import { useIdleTimer } from 'react-idle-timer';
import Utility from '../../utils/utility';



const useStyles = makeStyles((theme) => ({
    rootStyle :{
        width:"820px"
    },
    errorStyle :{
        color: '#282B3E ',
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "48px",
        fontWeight:'600',
        margin:"0",
        lineHeight: "58px"
    },
    labelStyle: {        
        fontWeight: "300",
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "34px"
    }    
}))

let { appId, jwt } = getQueryParams();

const PageBody = props => {
    const context = useContext(MyContext);
    const [page, setPage] = useState(props.page);    
    const classes = useStyles();

    
    const handleOnIdle = e => {
        if(context.sessionTimeoutEnable){
            setPage(10);
        }
    }

    // 15 min session timeout if in Idle mode
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const [formValues, setFormValues] = useState({
        phoneNumber: '',
        authUserId: '',
        authPassword: '',
        userName: "",
        pwd: "",
        confirmPwd: "",
        securityQuestion: null,
        answer: "",
        tcChecked: false,
        isLoading: false,
        appId: appId
        // passwordVisible: false,
        //confirmPasswordVisible: false,
    })


    const [isLoading, setLoading] = useState(false);

    const [errorType, setErrorType] = useState('');
    
    const [tokenInfo, setTokenInfo] = useState({});
    const [maintenanceDetails, setMaintenanceDetails] = useState(null);
    const [systemErrorCount, setSystemErrorCount] = useState(0);
    const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(false)
    const [appid, setAppid] = useState('')


    const [errorState, setErrorState] = useState({
        firstName: { id: 'firstname', msg: '', isError: false },
        lastName: { id: 'lastname', msg: '', isError: false },        
        phoneType: { id: 'phoneType', msg: '', isError: false },
        phoneNumber: { id: 'phoneNumber', msg: '', isError: false },
        userName: { id: 'userName', msg: '', isError: false },
        pwd: { id: 'pwd', msg: '', isError: false },
        confirmPwd: { id: 'confirmPwd', msg: '', isError: false },
        authUserId: { id: 'authUserId', msg: '', isError: false },
        authPassword: { id: 'authPassword', msg: '', isError: false },
        securityQuestion :{ id: 'securityQuestion', msg: '', isError: false },
        answer :{ id: 'answer', msg: '', isError: false },
        mfaType: { id: 'SMS', msg: '', isError: false },
    })      
 
    const [success, setSuccess] = useState(false);            

    const onClick = pageNum => {
        setSystemErrorCount(0);
        setPage(pageNum)
    }

    useEffect(() => {        
        setLoading(true);
        setAppid(appId);
        if (jwt && appId) {
            validateLink(appId);
        }
        else {
            setPage(6); 
            setLoading(false);            
        }
    }, [appId, jwt]);

    function validateLink(appid) {
        if (systemErrorCount > 0) setSystemErrorCount(0);
        let payload = {
            "appId": appid,
            "jwt": jwt
        }

        Service.linkValidate(payload)
            .then((response) => {               
                setLoading(false); 
                if (Constants.VALIDATE_LINK_SUCCESS_2000===response.code.toString()) {
                    setTokenInfo(JSON.parse(response.details));
                    setAppid(appId);
                    setPage(0);
                    if (systemErrorCount > 0) setSystemErrorCount(0);
                } else if (response.code === "9065" || response.code === "9064" || response.code === "2003" ||
                            response.code === "9005") {
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(6);
                } else if (response.code === "5003") { // If user has abandoned before from DL verification page, then it will come here.
                    let res = JSON.parse(response.details);
                    let redirectURL = res.redirectURL+res.jwt;
                    Utility.redirectTo(window, redirectURL);
                } else if (response.code === "9015" || response.code === "9014" || response.code === "1009"){
                    setSystemErrorCount(0);
                    setPage(7);
                } else if (response.code === "200" && response.message ==="Maintenance"){
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(11);
                } else if (response.code === "200" && response.message ==="Technical"){
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(12);
                } else if (response.code === "5004"){
                    setPage(14);
                } else {
                    // Unhandled error codes
                    setPage(7);
                    setSystemErrorCount(systemErrorCount + 1);
                }
            }).catch(error => {
                setLoading(false);
                console.log("link validate Error ========>", error);
                if (error.message === "Network Error") {
                    setErrorType("Technical")
                }
                setPage(7);                
            })
    }

    if(success) {
        return <CongratulationsPage/>
    }
    
    switch (page) {
        case 0:
            return (               
                <RegistrationColumnLayout
                    step={page}
                    text="Create your CareGiver profile"
                    errorType={errorType}
                    tokenInfo={tokenInfo}
                    maintenanceDetails={maintenanceDetails}
                    subText={`Hi ${tokenInfo.providerfn},`}
                >
                    <MyNotification />
                    {
                        isLoading && <LoaderComponent/>
                    }
                    <RegistrationPage onClick={onClick} appId={appId} isLoading={isLoading} setSessionTimeoutEnable={setSessionTimeoutEnable} formValues={formValues} tokenInfo={tokenInfo} setFormValues={setFormValues} setErrorState={setErrorState} setSystemErrorCount={setSystemErrorCount}/>
                </RegistrationColumnLayout>
            );
        case 1:
            return (
                <RegistrationColumnLayout
                step={page}
                text="CareGiver registration"
                errorType={errorType}
                tokenInfo={tokenInfo}
                maintenanceDetails={maintenanceDetails}
                subText={`Review your first and last name, then enter your mobile number to register your profile.`}
            >                
                <PhoneValidationPage onClick={onClick}
                    formValues={formValues}
                    isLoading={isLoading}
                    setFormValues={setFormValues}
                    tokenInfo={tokenInfo}
                    appId={appId}
                    setErrorState={setErrorState}
                    systemErrorCount={setSystemErrorCount}                     
                />
            </RegistrationColumnLayout>
            );
        case 2:
                return (
                    <RegistrationColumnLayout
                    step={page}
                    text="Verify your registration"
                    errorType={errorType}
                    tokenInfo={tokenInfo}
                    maintenanceDetails={maintenanceDetails}
                    subText={`Great, now let's verify your identity.`}
                >                    
                    <MfaTypeComponent  
                        formValues={formValues}
                        isLoading={isLoading}
                        setFormValues={setFormValues}
                        tokenInfo={tokenInfo}
                        appId={appId}
                        setErrorState={setErrorState}
                        setSystemErrorCount={setSystemErrorCount}
                        systemErrorCount={systemErrorCount}
                        onClick={onClick} 
                    />
                </RegistrationColumnLayout>                 

                );
        case 3:
                return (
                    <RegistrationColumnLayout
                    step={page}
                    text="We’ve sent you a code"
                    errorType={errorType}
                    tokenInfo={tokenInfo}
                    maintenanceDetails={maintenanceDetails}                    
                    subText={`Your code is valid for 10 minutes. If your code has expired, you can request a new one below. Please note that you will be logged out after too many failed attempts.`}
                    >                    
                    <MfaVerificationComponent  
                        formValues={formValues}
                        isLoading={isLoading}
                        setFormValues={setFormValues}
                        tokenInfo={tokenInfo}
                        appId={appId}
                        setErrorState={setErrorState}
                        systemErrorCount={setSystemErrorCount}
                        onClick={onClick} 
                        setPage={setPage}
                    />
                </RegistrationColumnLayout>
                );
        case 4:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We’re sorry.</p>
                <label className={classes.labelStyle}>
                You have exceeded the number of validation attempts.
                </label>
                <StartOverComponent/>
                </div>

            );
        case 5:
                return (
                    <div className={classes.rootStyle}>
                    <p className={classes.errorStyle}>Registration complete!</p>
                    <label className={classes.labelStyle}>
                    <br/>Download the CareGiver mobile app, where you can manage and submit care sessions for {tokenInfo.insuredfn} {tokenInfo.insuredln}’s long-term care policy.
                    </label>                    
                    <CongratulationsPage/>
                    </div>
    
                );
        case 6:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Hmm...</p>
                <label className={classes.labelStyle}>
                    It looks like this invite is no longer valid. To register your CareGiver profile, contact your customer and request them to re-send the invite.
                </label>
                </div>

            );
        case 7:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Hmm...</p>
                <label className={classes.labelStyle}>
                    It looks like we're having some trouble generating this page. You can try again after some time by clicking on your email link.
                </label>
                </div>

            );
        case 8:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We’re sorry.</p>
                    <label className={classes.labelStyle}>
                    You have exceeded the number of validation attempts.
                    </label>
                    <StartOverComponent/>
                </div>
    
        );
        case 9:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Hmm...</p>
                <label className={classes.labelStyle}>
                    It looks like we're having some trouble generating your code. You can try again after some time by clicking on your email link.
                </label>
                </div>    
        );
        case 10:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Your session has expired due to inactivity.</p><br/>
                <label className={classes.labelStyle}>For your security, we haven’t saved any of your personal data. Please start registering your CareGiver profile again.           
                </label>
                <StartOverComponent/>
                </div>

            );
        case 11:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We are currently down for scheduled maintenance.</p>
                <label className={classes.labelStyle}>
                We are working as quickly as possible to restore our services and apologize for any inconvenience.
                </label>
                </div>    
            );
        case 12:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We are currently experiencing technical issues.</p>
                <label className={classes.labelStyle}>
                We are working as quickly as possible to restore our services and apologize for any inconvenience.
                </label>
                </div>    
            );
        case 13:
                return (
                    <div className={classes.rootStyle}>
                    <p className={classes.errorStyle}>Unverified flow begins here</p>
                    <label className={classes.labelStyle}>
                    Unverified flow begins here
                    </label>
                    </div>    
                );
        case 14:
            return <ErrorPage/>
        default:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Hmm...</p>
                <label className={classes.labelStyle}>
                    It looks like we're having some trouble generating this page. You can try again after some time by clicking on your email link.
                </label>
                </div>

            );
    }    
};

export default PageBody;