export const SUCCESS_CODE_0000 = "0000";
export const VALIDATE_LINK_SUCCESS_2000 = "2000";
export const TOKEN_EXPIRED_CODE_2001 = "2001";
export const TOKEN_INVALID_CODE_2002 = "2002";
export const TOKEN_EXTRACT_EXCEPTION_CODE_2003 = "2003";
export const MDM_EXCEPTION_CODE_9007 = "9007";
export const CACHE_SAVE_FAILED_9008 = "9008";
export const UNKWON_EXCEPTION_CODE_9009 = "9009";
export const MDM_PARTY_NOT_FOUND_CODE_9011 = "9011";
export const USERNAME_NOT_AVAILABLE_CODE_9057 = "9057";

export const SUCCESS_CODE_2001 = "2001";
export const POLICY_FAILED_3_CODE_9058 = "9058";
export const INVALID_APP_ID_9009 = "9009";
export const CACHE_SERVICE_FAILED_CODE_9002 = "9002";
export const POLICY_VALIDATION_FAILED_CODE_9004 = "9004";
export const ISAM_GROUP_UPDATE_FAILED_CODE_9999 = "9999";
export const ISAM_SEARCH_FAILED_CODE_9014 = "9014";

export default {
    SUCCESS_CODE_0000,
    TOKEN_EXPIRED_CODE_2001,
    TOKEN_INVALID_CODE_2002,
    TOKEN_EXTRACT_EXCEPTION_CODE_2003,
    MDM_EXCEPTION_CODE_9007,
    CACHE_SAVE_FAILED_9008,
    UNKWON_EXCEPTION_CODE_9009,
    MDM_PARTY_NOT_FOUND_CODE_9011,
    USERNAME_NOT_AVAILABLE_CODE_9057,

    SUCCESS_CODE_2001,
    POLICY_FAILED_3_CODE_9058,
    INVALID_APP_ID_9009,
    CACHE_SERVICE_FAILED_CODE_9002,
    POLICY_VALIDATION_FAILED_CODE_9004,
    ISAM_GROUP_UPDATE_FAILED_CODE_9999,
    ISAM_SEARCH_FAILED_CODE_9014,
    VALIDATE_LINK_SUCCESS_2000
};