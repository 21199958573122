import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const font = "\"Manulife JH Sans\"";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    textLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    contactLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    closeLabelStyles: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "13px",
        lineHeight: "18px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    contactSeparation: {
        paddingBottom: "20px"
    }
}));

const ErrorPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.errorHeaderStyles}>We’re having trouble setting up your account online.</span>
            </Grid>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>Please give us a call so we can assist you.</span>
            </Grid>
            <Grid container xs={12} className={classes.contactSeparation}>
                <Grid item xs={12}>
                    <span className={classes.textLabelStyles}>Customer Center</span>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.contactLabelStyles}>Call us at <a href="tel:1-844-798-3001"><u>1-844-798-3001</u></a>.</span>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.contactSeparation}>
                <Grid item xs={12}>
                    <span className={classes.textLabelStyles}>Monday - Friday, 8:00AM to 5:00PM EST</span>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.closeLabelStyles}>Closed on Saturday, Sunday and public holidays</span>
                </Grid>
            </Grid>           
        </div>
    );
};

export default ErrorPage
