
export function isEmpty (data) {
    let empty = false;
    if(!data || !data.trim()) empty = true
    return empty;
}


export function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^[a-zA-Z0-9](([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const repeatRegex = /([@#!#$%&'*+-/=?^_`{|])\1/
    return (re.test(String(email).toLowerCase()) && !repeatRegex.test(String(email).toLowerCase()));
}


export function getErrorMessage(key) {
    if(key === 'pwd') key = "secret"; // to fix snyk issue

    let validations =  {
        "userName": "Enter username",
        "userNameLen" : "Length should be more than 8 characters",
        "userNameSpecialChars" : "Please use only .-_@ as special characters in user name",
        "secret": "Enter password",
        "confirmPwd": "Enter confirm password",
        "confirmPwdNotMatched": "Confirm password is not matching",
        "securityQuestion": "Select security question",
        "tcChecked": "Please agree to terms & conditions",
        "answer": "Enter answer",
        "userNameDuplicate": "This username belongs to another profile. Please choose a different one.",
        "LNPhoneNumberFailed":"Phone validation failed",
        "icorrectPhoneNumber":"The number does not match our database. Please try again.",
        "phoneNumber":"Be sure to enter your mobile number to proceed",
        "mfaType":"Please choose one from above",
        "mfaCode":"Please enter code"
    }
    return validations[key];
}

export default {
    isEmpty, validateEmail
};