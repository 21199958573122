export const APP_ID = "266977";

// Codes
export const ERROR_CODE = 300;
export const SUCCESS_CODE_0000 = "0000";
export const SUCCESS_CODE_0001 = "0001";
export const SUCCESS_CODE_1001 = "1001";
export const SUCCESS_CODE_2001 = "2001";


//Pages
export const PAGE_MFA_TYPE_1 = 1;
export const PAGE_MFA_VARIFY_2 = 2;
export const PAGE_FRESH_FLOW_PAGE_3 = 3;
export const PAGE_CONGRATULATION_PAGE_4 = 4;
export const PAGE_ERROR_5 = 5;
export const FONT_FAMILY = "\"Manulife JH Sans\"";
export const FONT_MFA_TEXT = "\"Manulife JH Sans\"";
export const CSS_DISPLAY = "inline-block";
export const PAGE_SESSION_EXPIRED_10 = 10;

export default {
    APP_ID,
    ERROR_CODE,
    SUCCESS_CODE_0000,
    SUCCESS_CODE_0001,
    SUCCESS_CODE_1001,
    SUCCESS_CODE_2001,
    PAGE_MFA_TYPE_1,
    PAGE_MFA_VARIFY_2,
    PAGE_FRESH_FLOW_PAGE_3,
    PAGE_CONGRATULATION_PAGE_4,
    PAGE_ERROR_5,
    PAGE_SESSION_EXPIRED_10,
    FONT_FAMILY,
}